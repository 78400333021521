import Dashboard from "../pages/Dashboard"
import Message from "../pages/Dashboard/pages/Messages/Message"
import Signin from "../pages/signin"

const publicRoutes = [
  {
    name: "Home",
    path: "/",
    component: <Signin />,
  },
]

const privateRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    name: "View Order Messages",
    path: "/dashboard/message/:id",
    component: <Message />,
  },
]

const authRoutesNoDash = []

const main = {
  publicRoutes,
  privateRoutes,
  authRoutesNoDash,
}

export default main
