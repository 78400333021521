import React from 'react';
import * as THREE from "three";
import threeGlobe from 'three-globe';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';

const Earth = ({ size,data,addItem }) => {
    size = size || 1
    const { useRef, useEffect } = React
  let mount = useRef(null);
  useEffect(()=>{
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, (window.innerWidth/500)/size, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({alpha:true});
    renderer.setSize(window.innerWidth/size, 500);
    

    // const N = 300;
    // const data = [...Array(N).keys()].map(() => ({
    //   lat: (Math.random() - 0.5) * 180,
    //   lng: (Math.random() - 0.5) * 360,
    //   size: Math.random() / 3,
    //   color: ["red", "white", "blue", "green"][Math.round(Math.random() * 3)]
    // }));
      
      
    // let data = []

    // const addAddress = (lat,lng) => {
    //   data.push({
    //     lat,
    //     lng,
    //     size: .01,
    //     color:"green"})
    // }
    // navigator.geolocation.getCurrentPosition((pos, error) => {
    //   const crd = pos.coords
    //   if (error) console.log(error)
    //   addAddress(crd.latitude,crd.longitude)
    // })
    

    
   
    const globe = new threeGlobe()
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .pointsData(data)
      .pointAltitude("size")
        .pointColor("color")
        .showAtmosphere(true)
          .atmosphereColor("lightskyblue")
      .atmosphereAltitude(.15)


    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    mount.appendChild( renderer.domElement );
    // var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    // var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    // var cube = new THREE.Mesh(geometry, material);
    scene.add(new THREE.AmbientLight(0xffffff));
    scene.add(new THREE.DirectionalLight(0xffffff, 1));
    // scene.add(cube)
    scene.add(globe)
    camera.position.z = 500;

    const tbControls = new TrackballControls(camera, renderer.domElement);
    tbControls.minDistance = 101;
    tbControls.rotateSpeed = 5;
    tbControls.zoomSpeed = 0.8;
    const animate = () => {
      requestAnimationFrame(animate);
    //   cube.rotation.x += 0.01;
    //   cube.rotation.y += 0.01;
      tbControls.update();
      renderer.render(scene, camera);
    }
    
    animate();
    // console.log("loop")
  },[data,size])

  
  return <div className="vis" ref={ref => (mount = ref)}   />
}
 
export default Earth;