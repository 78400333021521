import {
  ExitToApp,
  Home,
  Message,
  People,
  PieChart
} from "@material-ui/icons"
import React from "react"
import { useHistory } from "react-router-dom"
import { logout } from "../../API/Auth/email"
import Messages from "./pages/Messages"
import ServerInfo from "./pages/ServerInfo"
import UserLocations from "./pages/UserLocations"
import Users from "./pages/Users"
const DashboardTemplate = () => {
  const hist = useHistory()
  const spacing = 4

  const handleSignout = async () => {
    const res = await logout()
    if (res.status === 200) {
      hist.push("/")
    }
  }

  const navItems = [
    {
      link: "#top",
      name: "Home",
      icon: <Home />,
      color: "blue",
    },
    {
      link: "#users",
      name: "Users",
      icon: <People />,
      color: "purple",
    },
    {
      link: "#messages",
      name: "Messages",
      icon: <Message />,
      color: "green",
    },
    {
      link: "#statistics",
      name: "Statistics",
      icon: <PieChart />,
      color: "pink",
    },
  ]
  return (
    <div className="w-full h-full flex flex-row fixed ">
      <div
        id="nav-bar"
        className="bg-white-200  mx-auto  bg-white overflow-hidden h-screen "
      >
        <div className="p-2">
          <svg
            width="50"
            height="50"
            viewBox="0 0 474 474"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.5 289L209.5 309.5L217.357 304.5L216 287.5C218 284.5 223.6 274.2 230 257C226.5 235 232 238.5 236 234.5C239.2 231.3 255.667 238.5 263.5 242.5H270.5C270.526 258.222 270.685 267.06 269 282.5L273 283L273.25 290.75L256.5 289L220.5 302.5L217.357 304.5L209.5 309.5H186.5L182 316.5L158 332.5L102.5 320L90 332.5L83.5 341V355.5L79 376.5L149.5 430L246.5 450L356 414L420 345.5L374.5 325H359.5L332.5 307L327.5 309.5L321 302.5L310 299L293.5 292.5L290 283L297 251.5V243.5L304 226.5C304.5 222.833 305.7 213.4 306.5 205C313 197 309 193.5 314 189.5C318 186.3 322.667 188.833 324.5 190.5L337 189.5L343 182.5L329.5 172V166.5L321.5 156.5V153C323.333 150.833 327.1 146.2 327.5 145C327.9 143.8 326.333 143.5 325.5 143.5L321 147.5C317.833 150.167 310.1 155.7 304.5 156.5C298.9 157.3 287.167 172.5 282 180L273 187.5H268L256.5 195C248.5 196.5 228.1 201 210.5 207C192.9 213 184.167 230.5 182 238.5C180.333 242.333 176.6 251 175 255C173.4 259 179 259.667 182 259.5L190.5 245C193.159 258.664 195.725 259.457 199.5 266C199.658 274.908 199.902 279.889 197.5 289ZM282 284H280L278.5 280L280.5 256L282.5 239.5L284.5 239L283.5 243.5L282 284Z"
              fill="black"
            />
            <path
              d="M209.5 309.5L197.5 289C199.902 279.889 199.658 274.908 199.5 266C195.725 259.457 193.159 258.664 190.5 245L182 259.5C179 259.667 173.4 259 175 255C176.6 251 180.333 242.333 182 238.5C184.167 230.5 192.9 213 210.5 207C228.1 201 248.5 196.5 256.5 195L268 187.5H273L282 180C287.167 172.5 298.9 157.3 304.5 156.5C310.1 155.7 317.833 150.167 321 147.5L325.5 143.5C326.333 143.5 327.9 143.8 327.5 145C327.1 146.2 323.333 150.833 321.5 153V156.5L329.5 166.5V172L343 182.5L337 189.5L324.5 190.5C322.667 188.833 318 186.3 314 189.5C309 193.5 313 197 306.5 205C305.7 213.4 304.5 222.833 304 226.5L297 243.5V251.5L290 283L293.5 292.5L310 299L321 302.5L327.5 309.5L332.5 307L359.5 325H374.5L420 345.5L356 414L246.5 450L149.5 430L79 376.5L83.5 355.5V341L90 332.5L102.5 320L158 332.5L182 316.5L186.5 309.5H209.5ZM209.5 309.5L217.357 304.5M290 292.5L273.25 290.75M273.25 290.75L256.5 289L220.5 302.5L217.357 304.5M273.25 290.75L273 283L269 282.5C270.685 267.06 270.526 258.222 270.5 242.5H263.5C255.667 238.5 239.2 231.3 236 234.5C232 238.5 226.5 235 230 257C223.6 274.2 218 284.5 216 287.5L217.357 304.5M280 284H282L283.5 243.5L284.5 239L282.5 239.5L280.5 256L278.5 280L280 284Z"
              stroke="black"
            />
            <circle cx="237" cy="237" r="221" stroke="black" strokeWidth="32" />
          </svg>
        </div>
        {navItems.map((x) => {
          return (
            <div
              key={`nav-item-${x.name}`}
              className={`flex flex-col gap-x-${spacing} p-2`}
            >
              <a
                href={x.link}
                className={`hover:bg-${x.color}-100   rounded-xl flex  p-5`}
              >
                <div className={`text-${x.color}-500 `}>{x.icon}</div>
                <p className={`pl-7 font-bold uppercase text-${x.color}-500`}>
                  {x.name}
                </p>
              </a>
            </div>
          )
        })}
        <div className="flex flex-col gap-y-5  p-2">
          <button
            href="#users"
            className="hover:bg-gray-100  rounded-xl flex p-5  "
            onClick={handleSignout}
          >
            <ExitToApp className="text-gray-700 " />
            <p className="pl-7 font-bold uppercase">Sign out</p>
          </button>
        </div>
      </div>

      <div
        id="content"
        className="w-full ml-3 min-h-screen hover:w-40 overflow-hidden bg-gray-200 "
      >
        <div
          id="menu-bar-top"
          className="bg-white min-w-full h-20 text-2xl uppercase text-center"
        ></div>
        <div id="top" className="h-screen max-h-screen flex flex-col items-center justify-center">
          <ServerInfo />
          <div className="home bg-white p-40 rounded-xl text-lg font-bold">
            Welcome to the Dashboard
          </div>
        </div>
       
        <div id="statistics" className="h-screen">
       
          <UserLocations />
        </div>
        <div id="users" className="h-screen">
          <Users />
        </div>
        <div id="messages" className="h-screen">
          <Messages />
        </div>
        {/* <div id="home" className="w-screen h-screen"></div> */}
      </div>
    </div>
  )
}

export default DashboardTemplate
