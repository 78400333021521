import React, { useEffect } from "react"
import ipAPI from "../../../../API/ips"
import Earth from "../../../../components/Earth"
const UserLocations = () => {
  const datahasLoaded = React.useRef(false)
  const [state, setState] = React.useState({
    addresses:[]
  })
  useEffect(() => {
    const init = async () => {
      await ipAPI.getAll().then((x) => {
        if (x?.data) {
          console.log(x.data?.addresses)
          setState({addresses:x.data.addresses})
        }
      })
    }

    if (datahasLoaded.current === false) {
      init()
      datahasLoaded.current = true
    }
  }, [])
  return (
    <div>
      <h2 className="text-center p-2 bg-gray-100">User Statistics</h2>
      <Earth size={1} />
      <div>
        {state.addresses.map(x => (<>
          <p>{state?.addresses?.address}</p>
          <p>{state?.addresses?.lat}</p>
          <p>{state?.addresses?.lng}</p>
        </>))}
      </div>
    </div>
  )
}

export default UserLocations
