import axios      from "axios"

import { isAuth } from "../Auth/email"
const base = `${process.env.REACT_APP_API}/os`

/**
 * @description get os info
 */
const getInfo = async () => {
	const url = `${base}/info`
	const jwt = isAuth()
	if (!jwt) {
		return "error"
	}

	return await axios.post(
		url,
		{},
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			withCredentials: true,
		}
	)
}
const main = {
	getInfo
}
export default main