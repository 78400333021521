import { DeleteForever, Visibility } from "@material-ui/icons"
import React from "react"
import { Link } from "react-router-dom"
import { getAll } from "../../../../API/orderMessages"
import parsePhoneNumber from 'libphonenumber-js'

const Messages = () => {
  const [state, setState] = React.useState({
    messages: [],
  })
  const hasRetrievedData = React.useRef(false)

  React.useEffect(() => {
    const init = async () => {
      if (hasRetrievedData.current === false) {
        //get data
        const response = await getAll()
        if (response?.data?.orderMessages) {
            // console.log(response.data.orderMessages)
            const temp = response.data.orderMessages.map(x => {
              return {...x,phone:parsePhoneNumber("+1"+x.phone).formatNational()}
           })
          setState({ ...state, messages: temp, })
        } else {
          alert("fail")
        }
        hasRetrievedData.current = true
      }
    }
    init()
    // console.log("loop")
    return () => {}
  }, [state])

  const handleDelete = () => {}
  return (
    <div className="overflow-x-auto">
      <h2 className="text-center p-2 bg-gray-100">Order Messages</h2>
      <div className="min-w-screen flex items-center justify-center  font-sans overflow-hidden">
        <div className="w-full lg:w-5/6">
          <div className="bg-white shadow-md rounded my-6">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Id</th>
                  <th className="py-3 px-6 text-center">Name</th>
                  <th className="py-3 px-6 text-center">Message</th>
                  <th className="py-3 px-6 text-center">Phone</th>
                  <th className="py-3 px-6 text-center">Contact Day</th>
                  <th className="py-3 px-6 text-center">Contact Time</th>
                  <th className="py-3 px-6 text-center">View</th>
                  <th className="py-3 px-6 text-center">Delete</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {state?.messages?.length>0? state?.messages?.map((x, i) => (
                  <tr key={"order-message"+i}  className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="font-medium">{x._id}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex items-center justify-center">
                        <span>{x.user.name}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center">
                        {x.message?.substring(0, 30) + "..."}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="flex items-center justify-center">
                        {x.phone  }
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="flex items-center justify-center">
                        {x.meeting.day}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="flex items-center justify-center">
                        {x.meeting.time}
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                          <Link to={`/dashboard/message/${x._id}`}>
                            {" "}
                            <Visibility />{" "}
                          </Link>
                        </div>
                      </div>
                    </td>

                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                          <DeleteForever onClick={() => handleDelete(x._id)} />
                        </div>
                      </div>
                    </td>
                  </tr>
                )):null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages
