import axios from "axios"
import { isAuth } from "../Auth/email"

//http://localhost:8000/api
const base = `${process.env.REACT_APP_API}/order/messages`

export const getAll = async () => {
  const url = `${base}/get-all`
  const jwt = isAuth()
  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

const getSingle = async (id) => {
    const url = `${base}/get-single`
    const jwt = isAuth()
    return await axios.post(
      url,
      {id},
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        withCredentials: true,
      }
    )
  }

const main = {
    getAll,
    getSingle
}
export default main
