import { Redirect, Route } from "react-router-dom"
import { GlobalContext } from "../../../contexts/GlobalState"
import React from "react"

function PrivateRoute({ children, ...rest }) {
  const globalState = React.useContext(GlobalContext)

  let isAuth = globalState.auth.isAuth()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
