import axios      from "axios"
import { isAuth } from "../Auth/email"

const base = `${process.env.REACT_APP_API}/admin`

export const getUsers = async () => {
  const url = `${base}/get-users`
  const jwt = isAuth()

  return await axios.post(
    url,
    { },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

/**
 * @description takes a user id and deletes it
 * @param {String} userId
 */
export const deleteUser = async (userId) => {
  const url = `${base}/delete-user`
  const jwt = isAuth()

  return await axios.post(
    url,
    { userId },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

/**
 * @description gets a list of all the payments
 * @param {Number} limit
 */
export const getPayments = async (limit) => {
  const url = `${base}/get-payments`
  const jwt = isAuth()

  return await axios.post(
    url,
    { limit },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

/**
 * @description gets emails
 */
export const getEmails = async () => {
  const emailBaseUrl = `${process.env.REACT_APP_API}/email/get-mail`
  const jwt = isAuth()

  return await axios.post(
    emailBaseUrl,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}


