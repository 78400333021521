import { DeleteForever } from "@material-ui/icons"
import React from "react"
import { deleteUser, getUsers } from "../../../../API/admin"

const Users = () => {
  const [state, setState] = React.useState({
    users: [],
  })
  const hasfetchedData = React.useRef(false)

  React.useEffect(() => {
    const init = async () => {
      const response = await getUsers()
      if (response.status === 200) {
        const users = response.data.users

        setState({ ...state, users })
      } else {
        alert("error could not get data")
      }
    }
    if (hasfetchedData.current === false) {
      init()
      hasfetchedData.current = true
    }
  }, [state])

  const handleDelete = async (id) => {
    const res = await deleteUser(id)
    if (res.status === 200) {
      alert("user successfully deleted")
      const temp = state.users.filter((x) => x._id !== id)
      setState({ ...state, users: temp })
    }
  }

  return (
    <div className="overflow-x-auto">
      <h2 className="text-center p-2 bg-gray-100">Users</h2>
      <div className="min-w-screen flex items-center justify-center  font-sans overflow-hidden">
        <div className="w-full lg:w-5/6">
          <div className="bg-white shadow-md rounded my-6">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Id</th>
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-center">Email</th>
                  <th className="py-3 px-6 text-center">Role</th>
                  <th className="py-3 px-6 text-center">Delete</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {state?.users?.map((x, i) => (
                  <tr key={ `user-${i}`} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="mr-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="24"
                            height="24"
                            viewBox="0 0 48 48"
                            style={{ fill: "#000000" }}
                          >
                            <path
                              fill="#80deea"
                              d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"
                            ></path>
                            <path
                              fill="#80deea"
                              d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"
                            ></path>
                            <path
                              fill="#80deea"
                              d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"
                            ></path>
                            <circle
                              cx="24"
                              cy="24"
                              r="4"
                              fill="#80deea"
                            ></circle>
                          </svg>
                        </div>
                        <span className="font-medium">{x._id}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex items-center">
                        <span>{x.name}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center">
                        {x.email}
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="flex items-center justify-center">
                        {x.role}
                      </div>
                    </td>

                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div className="w-4 mr-2 transform hover:text-red-500 hover:scale-110">
                          <DeleteForever onClick={() => handleDelete(x._id)} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users
