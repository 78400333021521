import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivateRoute from "./components/auth/PrivateRoute"
import SnackbarProvider from "./contexts/SnackbarState"
import Signin from "./pages/signin"
import routes from "./routes"
export default function App() {
  return (
    <SnackbarProvider>
      <Router>
        <Switch>
          {routes.privateRoutes.map((x,i) => (
            <PrivateRoute key={`route-i` } path={x.path} exact>
              {x.component}
            </PrivateRoute>
          ))}
          <Route path="/" exact>
            <Signin />
          </Route>
        </Switch>
      </Router>
    </SnackbarProvider>
  )
}
