import ComputerIcon from "@material-ui/icons/Computer"
import DnsIcon from "@material-ui/icons/Dns"
import MemoryIcon from "@material-ui/icons/Memory"
import SpeedIcon from "@material-ui/icons/Speed"
import React from "react"
import OSAPI from "../../../../API/OS"
import InfoCard from "../../../../components/InfoCard"
const ServerInfo = () => {
  const hasFetchedData = React.useRef(false)
  const [state, setState] = React.useState({
    arch: "",
    cpus: [],
    memoryFree: "",
    memoryTotal: "",
    platform: "",
  })
  React.useEffect(() => {
    const init = async () => {
      const response = await OSAPI.getInfo()
      if (response?.data) {
        const os = response.data.os

        setState({
          ...state,
          arch: os.arch,
          cpus: os.cpu,
          memoryFree: os.memory.free,
          memoryTotal: os.memory.total,
          platform: os.platform,
        })
      }
    }
    if (hasFetchedData.current === false) {
      init()
      hasFetchedData.current = true
      }
     
  }, [state])
  return (
    <div id="os-info" className="flex flex-row justify-center flex-wrap pl-40 pr-40">
      <InfoCard text="Architecture" info={state.arch} svg={<DnsIcon />} />
      <InfoCard
        text="CPUS"
        info={state.cpus.count?.length}
        svg={<SpeedIcon />}
        color={"purple"}
      />
      <InfoCard
        text="Memory free"
        info={state.memoryFree}
        svg={<MemoryIcon />}
        color={"blue"}
      />
      <InfoCard
        text="Memory total"
        info={state.memoryTotal}
        svg={<MemoryIcon />}
        color={"blue"}
      />
          <InfoCard text="Platform" info={state.platform} svg={<ComputerIcon />} color={ "yellow"}/>
    </div>
  )
}

export default ServerInfo
