import { ArrowBack } from "@material-ui/icons"
import React from "react"
import { useHistory, useParams } from "react-router-dom"
import orderMessages from "../../../../../API/orderMessages"

const Message = () => {
  const [state, setState] = React.useState({
    message: "",
  })
  const { id } = useParams()
  const hist = useHistory()
  const hasFetchedData = React.useRef(false)
  React.useEffect(() => {
    const init = async () => {
      const response = await orderMessages.getSingle(id)
      if (response?.data?.orderMessage) {
        setState({ ...state, message: response.data.orderMessage.message })
      }
    }
    if (hasFetchedData.current === false) {
      init()
      hasFetchedData.current = true
    }
  }, [id, state])

  const handleClick = () => {
    hist.goBack()
  }

  return (
    <div className="bg-gray-100 ">
      <button
        className="text-white border-none bg-green-500 p-3 rounded-xl m-5 "
        onClick={handleClick}
      >
        {" "}
        <ArrowBack />
        Back
      </button>
      <div className="bg-white flex flex-col   shadow-xl mx-96 min-h-screen rounded-xl ">
        <h2 className="text-2xl mx-auto p-5 ">Message</h2>
        <p className="mx-auto">{state?.message}</p>
      </div>
    </div>
  )
}

export default Message
