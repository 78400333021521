import React from "react"
import {
  emailLogin,
  emailSignup,
  forgotPassword, getProfile, isAuth
} from "../../API/Auth/email"

//APPWRITE
// const obj = {
//   currentUser: async ()=> await appwrite.account.get(),
//   auth: {
//     logout: async () => await appwrite.account.deleteSession("current"),
//     login: async (email, password) =>
//       await appwrite.account.createSession(email, password),
//     signup: async (email, password, name) =>
//       await appwrite.account.create(email, password, name),
//     changePassword: async (newPass, oldPass) => await appwrite.account.updatePassword(newPass, oldPass),
//     deleteAccount: async () => await appwrite.account.delete(),
//     isAuth: async () => {
//       const x = await appwrite.account.get()
//       if (!x.name) return false
//       return x.name
//     },
//   },
// }

//TEMPLATE
// const obj = {
//   currentUser: async () => {},
//   auth: {
//     logout: ()=>{},
//     login: ()=>{},
//     signup: ()=>{},
//     changePassword: ()=>{},
//     deleteAccount: ()=>{},
//     isAuth:  () => {},
//   },
// }


const obj = {
  auth: {
    currentUser: async() => {
      return await getProfile()
    },
    isAuth: () => {
      return isAuth()
    },
   
    login: async (email, password) => {
      return await emailLogin(email, password)
    },
    signup: async (name, email, password) => {
      await emailSignup(name, email, password)
    },
    passwordRecovery: async (email) => {
      await forgotPassword(email)
    },
  },
  cart: {
    saveCart: (item) => localStorage.setItem("cart", JSON.stringify(item)),
    retrieveCart: () => JSON.parse(localStorage.getItem("cart")),
  },
}

export const GlobalContext = React.createContext(obj)

const GlobalState = ({ children }) => {
  const [state, setState] = React.useState(obj)

  // React.useEffect(() => {
  //   const init = () => {
     
  //   }
  //   init()
  // }, [])

  return (
    <GlobalContext.Provider value={{...state,setState}}>{children}</GlobalContext.Provider>
  )
}

export default GlobalState
