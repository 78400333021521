import axios from "axios"
import Cookies from "js-cookie"
import axiosSource from "../../helpers/axiosHelper"
import getPublicJwt from "../getpublicJwt"
//http://localhost:8000/api
const base = `${process.env.REACT_APP_API}/email`

export const emailSignup = async (name, email, password) => {
  const url = `${base}/signup`
  return await axios.post(url, {
    name,
    email,
    password,
  })
}

export const emailLogin = async (email, password) => {
  const url = `${base}/login`

  return await axios.post(
    url,
    { email, password },
    {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    }
  )
}

export const emailAdminLogin = async (email, password) => {
  const url = `${base}/login/admin`
  const jwt = isAuth()

  return await axios.post(
    url,
    { email, password },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

// export const logout = async () => {
// 	const url = `${base}/logout`

// 	//TODO remove cookie not local storage when logs out
// 	// localStorage.removeItem("jwt")
// 	return await axios.get(url, {
// 		headers: {
// 			"content-type": "application/json",
// 		},
// 		withCredentials: true,
// 	})
// }

export const getProfile = async () => {
  const url = `${base}/profile`
  const jwt = isAuth()
  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
      cancelToken: axiosSource.token,
    }
  )
}

//TODO fix isAuth
export const isAuth = () => {
  if (typeof window === undefined) return false
  const jwt = getPublicJwt()

  if (jwt) {
    return jwt
  }
  return false
}

export const logout = async () => {
  const url = `${base}/logout`
  const jwt = isAuth()
  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

export const isAdmin = () => {
  const role = Cookies.get("role")
  const isAdmin = role === "admin"
  return isAdmin
}

export const securityLogin = async (password) => {
  const jwt = isAuth()
  if (!jwt) {
    return "error"
  }
  return await axios.post(
    `${base}/secure-password-check`,
    { password },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

/**
 * @description sends reset code to email
 */
export const forgotPassword = async (email) => {
  return await axios.post(`${base}/forgot-password`, { email })
}

/**
 * @description changes password with jwt token
 * @param {String} newPassword
 * @param {String} token jwt token
 */
export const passwordReset = async (newPassword, token) => {
  return await axios.post(`${base}/reset-password`, {
    resetPassLink: token,
    newPassword,
  })
}
